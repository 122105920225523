import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: 'admin',
    loadComponent: () =>
      import('./features/admin/admin.component').then(
        (mod) => mod.AdminComponent
      ),
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'home',
        canActivate: [authGuard],
        loadComponent: () =>
          import('./features/home/home.component').then(
            (mod) => mod.HomeComponent
          ),
        children: [
          {
            path: '',
            redirectTo: 'playground',
            pathMatch: 'full',
          },
          {
            path: 'playground',
            loadComponent: () =>
              import('./features/playground/playground.component').then(
                (mod) => mod.PlaygroundComponent
              ),
          },
          {
            path: 'departments',
            loadComponent: () =>
              import('./features/departments/departments.component').then(
                (mod) => mod.DepartmentsComponent
              ),
          },
          {
            path: 'assistants',
            loadComponent: () =>
              import('./features/assistants/assistants.component').then(
                (mod) => mod.AssistantsComponent
              ),
          },
          {
            path: 'storage',
            loadComponent: () =>
              import('./features/storage/storage.component').then(
                (mod) => mod.StorageComponent
              ),
          },
          {
            path: 'api-keys',
            loadComponent: () =>
              import('./features/api-keys/api-keys.component').then(
                (mod) => mod.ApiKeysComponent
              ),
          },
          {
            path: 'channels',
            loadComponent: () =>
              import('./features/channel/channel.component').then(
                (mod) => mod.ChannelComponent
              ),
          },
          {
            path: 'conversations',
            loadComponent: () =>
              import('./features/conversations/conversations.component').then(
                (mod) => mod.ConversationsComponent
              ),
          },
          {
            path: 'integrations',
            loadComponent: () =>
              import('./features/integration/integration.component').then(
                (mod) => mod.IntegrationComponent
              ),
          }
        ]
      },
      {
        path: 'main',
        canActivate: [authGuard],
        loadComponent: () =>
          import('./features/main/main.component').then(
            (mod) => mod.MainComponent
          ),
      },
      {
        path: 'login',
        loadComponent: () =>
          import('./features/auth/login/login.component').then(
            (mod) => mod.LoginComponent
          ),
      },
      {
        path: 'sign-up',
        loadComponent: () =>
          import('./features/auth/sign-up/sign-up.component').then(
            (mod) => mod.SignUpComponent
          ),
      },
    ],
  },
  // {
  //   path: 'company-onboard',
  //   loadComponent: () =>
  //     import('./features/auth/company-onboard/company-onboard.component').then(
  //       (mod) => mod.CompanyOnboardComponent
  //     ),
  // },
  {
    path: '',
    loadComponent: () =>
      import('./features/landing/landing.component').then(
        (mod) => mod.LandingComponent
      ),
  },
  // {
  //   path: '**',
  //   loadComponent: () =>
  //     import('./features/landing/landing.component').then(
  //       (mod) => mod.LandingComponent
  //     ),
  // },
];
